export const FETCH_CONTACTS = 'fetchContacts'
export const FETCHED_CONTACTS = 'fetchedContacts'
export const CLEAR_CONTACTS = 'clearContacts'
export const FETCH_CONTACT = 'fetchContact'
export const FETCHED_CONTACT = 'fetchedContact'
export const CREATE_OR_EDIT_CONTACT = 'createOrEditContact'
export const CREATED_CONTACT = 'createdContact'
export const EDIT_CONTACT = 'editContact'
export const EDITED_CONTACT = 'editedContact'
export const NEW_CONTACT = 'newContact'
export const CONTACT_DISABLE_LOADING = 'contactDisableLoading'

// ADMIN
export const ADMIN_EDIT_CONTACT = 'adminEditContact'
export const ADMIN_DELETE_CONTACT = 'adminDeleteContact'
export const ADMIN_DELETED_CONTACT = 'adminDeletedContact'
export const ADMIN_EDITED_CONTACT = 'adminEditedContact'
export const ADMIN_CREATE_CONTACT = 'adminCreateContact'
