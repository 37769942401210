export const FETCH_TRADITIONAL_OFFICE_LIST = 'fetchTraditionalOfficeList'
export const FETCHED_TRADITIONAL_OFFICE_LIST = 'fetchedTraditionalOfficeList'
export const FETCH_TRADITIONAL_OFFICE = 'fetchTraditionalOffice'
export const FETCHED_TRADITIONAL_OFFICE = 'fetchedTraditionalOffice'
export const FETCH_TRADITIONAL_OFFICE_LIST_VENUE = 'fetchTraditionalOfficeListVenue'
export const FETCHED_TRADITIONAL_OFFICE_LIST_VENUE = 'fetchedTraditionalOfficeListVenue'
export const DELETE_TRADITIONAL_OFFICE = 'deleteTraditionalOffice'
export const ADMIN_DELETE_TRADITIONAL_OFFICE = 'adminDeleteTraditionalOffice'
export const FETCH_TRADITIONAL_OFFICE_DETAILS = 'fetchTraditionalOfficeDetails'
export const FETCHED_TRADITIONAL_OFFICE_DETAILS = 'fetchedTraditionalOfficeDetails'
export const COPY_TRADITIONAL_OFFICE = 'copyTraditionalOffice'
export const ADMIN_FETCH_TRADITIONAL_OFFICE_DETAILS = 'adminGetTraditionalOfficeDetails'
export const EXPORT_TRADITIONAL_OFFICE_LIST = 'exportTraditionalOfficeList'
export const EXPORTED_TRADITIONAL_OFFICE_LIST = 'exportedTraditionalOfficeList'
