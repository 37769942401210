export const FETCH_PROMOCODES = 'fetchPromoCodes'
export const FETCHED_PROMOCODES = 'fetchedPromoCodes'
export const FETCH_PROMOCODE = 'fetchPromoCode'
export const FETCHED_PROMOCODE = 'fetchedPromoCode'

export const CREATE_PROMOCODE = 'createPromoCode'
export const CREATED_PROMOCODE = 'createdPromoCode'
export const EDIT_PROMOCODE = 'editPromoCode'
export const EDITED_PROMOCODE = 'editedPromoCode'

export const FETCH_PROMOCODE_OPTIONS = 'fetchPromoCodeOptions'
export const FETCHED_PROMOCODE_OPTIONS = 'fetchedPromoCodeOptions'

export const DELETE_PROMOTION_CODE = 'deletePromotionCode'

export const CLIENT_APPLY_CODE = 'clientApplyCode'
export const CLIENT_APPLIED_CODE = 'clientAppliedCode'

