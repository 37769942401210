import React from 'react'
import PropTypes from 'prop-types'

const InitialAvatar = ({ initial, className = 'h-10 w-10 mr-4 text-2xl' }) => (
  <div className={`text-white rounded-full bg-orange-500 flex content-center items-center justify-center ${className}`}>
    {initial.toUpperCase()}
  </div>
)

InitialAvatar.propTypes = {
  initial: PropTypes.string,
  className: PropTypes.string,
}

export default InitialAvatar
