import React from 'react'
import PropTypes from 'prop-types'

const UserAdmin = ({ children }) => (
  <a href={'/admin'} className="flex items-center text-sm">
    {children}
  </a>
)

UserAdmin.propTypes = {
  children: PropTypes.node,
}

export default UserAdmin
