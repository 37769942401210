export const FETCH_SPACE_FUNCTIONS = 'fetchSpaceFunctions'
export const FETCHED_SPACE_FUNCTIONS = 'fetchedSpaceFunctions'

export const FETCH_SPACE_FUNCTION = 'fetchSpaceFunction'
export const FETCHED_SPACE_FUNCTION = 'fetchedSpaceFunction'

export const FETCH_CATEGORY_SPACE_FUNCTION = 'fetchCategorySpaceFunction'
export const FETCHED_CATEGORY_SPACE_FUNCTION = 'fetchedCategorySpaceFunction'
export const RESET_CATEGORY_SPACE_FUNCTION = 'resetCategorySpaceFunction'

export const CREATE_SPACE_FUNCTION = 'createSpaceFunction'
export const CREATED_SPACE_FUNCTION = 'createdSpaceFunction'
export const EDIT_SPACE_FUNCTION = 'editSpaceFunction'
export const EDITED_SPACE_FUNCTION = 'editedSpaceFunction'
export const DELETE_SPACE_FUNCTION = 'deleteSpaceFunction'
export const DELETED_SPACE_FUNCTION = 'deletedSpaceFunction'

export const FETCH_SPACE_BUILDINGS = 'fetchSpaceBuildings'
export const FETCHED_SPACE_BUILDINGS = 'fetchedSpaceBuildings'
