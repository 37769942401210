export const FETCH_SEARCH_OPTIONS = 'fetchSearchOptions'
export const SEARCH_OPTIONS_FETCHED = 'searchOptionsFetched'

export const SEARCH = 'search'
export const SEARCHED = 'searched'

export const FILTER_SEARCH = 'filterSearch'

export const SEARCH_LOOKOUT = 'searchLookOut'
export const SEARCHED_LOOKOUT = 'searchedLookOut'
export const SEARCH_LOOKOUT_RESET = 'searchLookOutReset'

export const SEARCH_POPULAR_DISPLAY = 'searchPopularDisplay'

export const SEARCH_PUBLIC = 'searchPublic'
export const SEARCHED_PUBLIC = 'searchedPublic'
