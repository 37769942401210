import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'forms/shared/fields/input'
import TextAreaField from 'forms/shared/fields/text-area'
import DropdownField from 'forms/shared/fields/dropdown'
import ContactField from 'forms/shared/fields/contact'

const Fields = ({ placeOptions, categoryOptions }) => (
  <div className="m-3">
    <Field
      type="text"
      name="name"
      groupClassName="my-2"
      placeHolder="Name..."
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="text"
      name="email"
      groupClassName="my-2"
      placeHolder="Email..."
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="tel"
      name="phone"
      groupClassName="my-2"
      placeHolder="Phone..."
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={ContactField}
    />
    <Field
      type="dropdown"
      name="city"
      options={placeOptions}
      placeholder={'Where are you looking?'}
      groupClassName="my-2"
      inputClassName="pl-2 pt-2 regular text-xs"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={DropdownField}
    />
    <Field
      type="dropdown"
      name="spaceCategory"
      options={categoryOptions}
      placeholder="What kind of space you need?"
      groupClassName="my-2"
      inputClassName="pl-2 pt-2 regular text-xs"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={DropdownField}
    />
    <Field
      type="number"
      name="capacity"
      groupClassName="my-2"
      placeHolder="How many people is this for?"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="text"
      name="details"
      rows={5}
      groupClassName="my-2"
      placeholder="Any other details before we get started?"
      inputClassName="block border text-xs border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={TextAreaField}
    />
  </div>
)

Fields.propTypes = {
  placeOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array.isRequired,
}

export default Fields
