import React from 'react'
import Toggler from 'hoc/toggler'
import PropTypes from 'prop-types'
import Image from 'react-image'
import NoImage from 'assets/images/default-profile.png'
import { withRouter } from 'react-router-dom'
import InitialAvatar from 'components/shared/avatar/initial'
import { ArrowDownIconWhite, ArrowDownIcon } from 'constants/imageMapping'
import ResizeImage from 'utils/resizeImage'
import UserRedirect from './user-redirect'
import UserMenu from './user-menu'

const UserDropdown = ({ username, profileRole, avatar, toggle, isOpen, dark }) => {
  const darkTextClass = 'text-primary-black'
  const whiteTextClass = 'text-white'

  const textClass = dark ? darkTextClass : whiteTextClass
  return (
    <li className="hidden sm:flex">
      <div
        className={`space-dropdown focus:outline-none flex cursor-pointer items-center ml-4 ${textClass}`}
      >
        <UserRedirect profileRole={profileRole}>
          {avatar ? (
            <Image
              src={[ResizeImage(avatar, { w: 100 }), NoImage]}
              className="rounded-full h-10 w-10 mr-4"
            />
          ) : (
            <InitialAvatar initial={username.charAt(0)} className="h-8 w-8 mr-4 text-base" />
          )}
        </UserRedirect>
        <div onClick={toggle} className="sm:flex items-center cursor-pointer">
          {username}
          <img
            width="8"
            height="6"
            src={ArrowDownIcon}
            alt="Flyspaces search icon"
            className={`transform ml-2 w-2 ${dark ? '' : 'hidden'} ${isOpen ? 'rotate-180' : ''}`}
          />
          <img
            width="8"
            height="6"
            alt="Flyspaces search icon"
            src={ArrowDownIconWhite}
            className={`transform ml-2 w-2 ${dark ? 'hidden' : ''} ${isOpen ? 'rotate-180' : ''}`}
          />
        </div>
      </div>
      {isOpen && <UserMenu profileRole={profileRole} />}
    </li>
  )
}

UserDropdown.propTypes = {
  username: PropTypes.string,
  avatar: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  profileRole: PropTypes.string,
  dark: PropTypes.bool,
}

UserDropdown.defaultProps = {
  dark: true,
}

export default withRouter(Toggler(UserDropdown))
