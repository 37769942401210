import React from 'react';
import PropTypes from 'prop-types';

const SpaceMenu = ({ list, onMouseLeave }) => (
  <ul className="space-menu absolute rounded bg-white shadow-md w-50 pt-6 pb-4 mt-10" onMouseLeave={onMouseLeave}>
    {list.map((menu, key) => (
      <li key={key} className="pl-6 mb-2 text-md text-primary-darkgray">
        {menu.url ? <a href={menu.url} className="font-bold hover:text-primary-orange">{menu.name}</a> : <p className='font-bold cursor-default'>{menu.name}</p>}
        <ul>
          {menu.children && menu.children.map((child, key) => (
            <li key={key} className="pl-8 mt-2 text-primary-lightgray hover:text-primary-orange">
              <a href={child.url}>{child.name}</a>
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

SpaceMenu.propTypes = {
  list: PropTypes.array,
  onMouseLeave: PropTypes.func.isRequired,
};

export default SpaceMenu;
