import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import hasDom from 'utils/hasDom'
import debounce from 'lodash.debounce'
import NavbarLogo from './logo'
import NavRight from './nav-right'

// Should've add shadow in here to differentiate the border
// between body and content. BUT SSR and window kinda conflict
const Navbar = ({ variant }) => {
  const location = useLocation()
  const [reachedTop, setReachedTop] = useState(true)

  const overlayClasses = 'bg-transparent nav-overlay'
  const normalClasses = 'bg-white'

  // const shouldOverlay = variant === '1' ? false : history.location.pathname === '/' && reachedTop

  const shouldOverlay = useMemo(() => {
    if (variant === '1') return false
    if (
      location.pathname === '/' ||
      location.pathname.split('/').slice(-2).join('/') === 'office-for-rent/inquire'
    )
      return reachedTop
    return false
  }, [variant, location.pathname, reachedTop])

  const rootClass = shouldOverlay ? overlayClasses : normalClasses

  const handleScroll = debounce(() => {
    const distance = window.pageYOffset

    if (distance > 10) {
      setReachedTop(false)
    } else {
      setReachedTop(true)
    }
  })

  useEffect(() => {
    if (hasDom) {
      document.body.style.overflowX = 'initial' // to enable scrolling listener
      window.addEventListener('scroll', handleScroll, true)

      return () => {
        document.body.style.overflowX = 'hidden'
        window.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [])

  return (
    <nav
      className={`navbar w-full fixed top-0 z-50 lg:px-14 ${rootClass} ${
        !shouldOverlay && !reachedTop ? 'shadow-md' : ''
      }`}
    >
      <div className="container">
        <div className="flex justify-between items-center ">
          <NavbarLogo dark={!shouldOverlay} />
          <NavRight dark={!shouldOverlay} />
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  variant: PropTypes.string,
}

const mapStateToProps = ({ variant }) => ({
  variant: variant.google_optimize_variant,
})

export default connect(mapStateToProps)(Navbar)
