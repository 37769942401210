import { handleActions } from 'redux-actions'
import * as types from 'actions/auth/types'

export const initialState = {
  authenticated: false,
  user: {
    roles: [],
    favorites: [],
  },
  token: null,
  csrfToken: null,
  processing: false,
}

const authReducer = handleActions(
  {
    [types.AUTHENTICATED](state, { payload }) {
      return {
        ...state,
        token: payload.token,
      }
    },
    [types.PROFILE_STORED](state, { user }) {
      return {
        ...state,
        authenticated: true,
        user: {
          ...user,
          roles: user.roles.map((role) => role.name.toLowerCase()),
        },
      }
    },
    [types.UPDATE_PROFILE](state) {
      return {
        ...state,
        processing: true,
      }
    },
    [types.PROFILE_UPDATED](state) {
      return {
        ...state,
        processing: false,
      }
    },
    [types.FETCHED_USER_FAVORITES](state, { favoritesData }) {
      return {
        ...state,
        user: { ...state.user, favoritesData },
      }
    },
    [types.ADD_FAVORITE](state, { id }) {
      return {
        ...state,
        user: {
          ...state.user,
          favorites: [...state.user.favorites, { id }],
        },
      }
    },
    [types.REMOVE_FAVORITE](state, { id }) {
      return {
        ...state,
        user: {
          ...state.user,
          favorites: state.user.favorites.filter((favorite) => favorite.id !== id),
        },
      }
    },
    [types.UPDATE_FORMPATH](state, { payload }) {
      const { formPath } = payload
      return {
        ...state,
        formPath,
      }
    },
  },
  initialState
)

export default authReducer
