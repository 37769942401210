import React from 'react'
import Toggler from 'hoc/toggler'
import PropTypes from 'prop-types'
import {
  SearchIconWhite,
  ArrowDownIconWhite,
  SearchIcon,
  ArrowDownIcon,
} from 'constants/imageMapping'
import SpaceMenu from './space-menu'
import list from './space-list'

const SpaceDropdown = ({ toggle, isOpen, dark }) => {
  const darkTextClass = 'text-primary-black'

  const whiteTextClass = 'text-white'

  const textClass = dark ? darkTextClass : whiteTextClass

  const toggleArrowCss = isOpen ? 'rotate-180' : ''

  return (
    <li className="relative color-gray-secondary hidden sm:flex">
      <span
        onClick={toggle}
        className={`space-dropdown focus:outline-none flex items-center text-base mr-6 cursor-pointer  ${textClass}`}
      >
        <img
          width="16"
          height="16"
          className={`h-4 mr-2 ${dark ? '' : 'hidden'}`}
          src={SearchIcon}
          alt="Flyspaces search icon"
        />
        <img
          width="16"
          height="16"
          className={`h-4 mr-2 ${dark ? 'hidden' : ''}`}
          src={SearchIconWhite}
          alt="Flyspaces search icon"
        />
        Find a Space
        <img
          width="8"
          height="6"
          src={ArrowDownIconWhite}
          className={`ml-2 w-2 transform ${toggleArrowCss} ${dark ? 'hidden' : ''}`}
          alt="Flyspaces search icon"
        />
        <img
          width="8"
          height="6"
          src={ArrowDownIcon}
          className={`ml-2 w-2 transform ${toggleArrowCss} ${dark ? '' : 'hidden'}`}
          alt="Flyspaces search icon"
        />
      </span>
      |{isOpen && <SpaceMenu onClick={toggle} list={list} />}
    </li>
  )
}

SpaceDropdown.propTypes = {
  Spacename: PropTypes.string,
  avatar: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  dark: PropTypes.bool,
}

SpaceDropdown.defaultProps = {
  dark: true,
}

export default Toggler(SpaceDropdown)
