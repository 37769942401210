import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Toggler from 'hoc/toggler'
import Modalhandler from 'components/shared/modal/modal-handler'
import ContactUsModal from 'components/shared/modals/contact-us'
import {
  MenuIcon,
  MenuIconWhite,
  CloseIcon,
  AppStoreBadge,
  PlayStoreBadge,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedInIcon,
} from 'constants/imageMapping'
import Logo from 'components/shared/footer/white-logo'
import SidebarMenuVersion from './sidebar-menu-mobile'

const SidebarMenu = ({ toggle, isOpen, dark }) => {
  const getInTouch = useRef()
  const location = useLocation()
  return (
    <>
      <li className="flex items-center">
        <span className="text-base ml-7.5 cursor-default select-none">
          {location.pathname.split('/').slice(-2).join('/') !== 'office-for-rent/inquire' && 'Menu'}
        </span>
        <img
          alt="toggle"
          onClick={toggle}
          height="16"
          width="20"
          className={`h-4 ml-4 cursor-pointer ${dark ? '' : 'hidden'}`}
          src={MenuIcon}
        />
        <img
          alt="toggle"
          height="16"
          width="20"
          onClick={toggle}
          className={`h-4 ml-4 cursor-pointer ${dark ? 'hidden' : ''}`}
          src={MenuIconWhite}
        />
      </li>
      <Modalhandler
        className="text-lg md:text-xl mt-2 text-secondary-lightgray cursor-pointer"
        title={
          <button ref={getInTouch} className="hidden">
            Get in Touch
          </button>
        }
      >
        {({ toggle }) => <ContactUsModal toggle={toggle}/>}
      </Modalhandler>
      {isOpen && (
        <>
          <div className="bg-menu-black fixed top-0 left-0 right-0 w-full md:h-auto h-screen py-10 justify-center hidden md:flex">
            <img
              alt="toggle"
              className="fixed top-0 right-0 mt-4 mr-4 cursor-pointer h-6"
              src={CloseIcon}
              onClick={toggle}
            />
            <div className="bg-menu-black w-full flex py-10 justify-center flex-wrap md:flex-nowrap">
              <div className="flex w-1/2 sm:w-1/3 md:w-auto px-5 md:px-5 lg:px-10 xl:px-20 flex-col relative">
                <div className="text-2xl mb-5">
                  <Logo/>
                </div>
                <a href="/contact-us" className="text-lg md:text-xl mt-2 text-secondary-lightgray">
                  Get in Touch
                </a>
                <a
                  href="/resource-center"
                  className="text-lg md:text-xl mt-2 text-secondary-lightgray"
                >
                  Resource Center
                </a>
                <a
                  href="https://blog.flyspaces.com/"
                  className="text-lg md:text-xl mt-2 text-secondary-lightgray"
                >
                  Blog
                </a>
                <a href="/passport" className="text-lg md:text-xl mt-2 text-secondary-lightgray">
                  Passport
                </a>
                <a
                  href="https://explore.flyspaces.com/reports-and-guides"
                  className="text-lg md:text-xl mt-2 mb-10 md:mb-20 text-secondary-lightgray"
                >
                  Reports &amp; Guides
                </a>
              </div>
              <div className="flex w-1/2 sm:w-1/3 md:w-auto px-5 lg:px-5 xl:px-10 text-primary-lightgray flex-col">
                <div className="text-lg font-bold mb-4">Got a Space?</div>
                <a
                  href="https://explore.flyspaces.com/how-it-works/list-your-space "
                  className="mt-2"
                >
                  List Your Space
                </a>
                <a
                  href="https://explore.flyspaces.com/how-it-works/manage-your-leads"
                  className="mt-2 mb-10 md:mb-0"
                >
                  Manage Your Venue
                </a>
              </div>
              <div className="flex w-1/2 sm:w-1/3 md:w-auto px-5 lg:px-5 xl:px-10 text-primary-lightgray flex-col">
                <div className="text-lg font-bold mb-4">City Guides</div>
                <a href="/philippines/manila" className="mt-2">
                  Manila Office Spaces
                </a>
                <a href="/singapore/singapore" className="mt-2">
                  Singapore Office Spaces
                </a>
                <a href="/indonesia/jakarta" className="mt-2">
                  Jakarta Office Spaces
                </a>
                <a href="/philippines/cebu" className="mt-2">
                  Cebu Office Spaces
                </a>
                <a href="/hong-kong/hong-kong" className="mt-2">
                  Hong Kong Office Spaces
                </a>
                <a href="/malaysia/kuala-lumpur" className="mt-2 mb-10 md:mb-0">
                  Kuala Lumpur Office Spaces
                </a>
              </div>
              <div className="flex w-1/2 sm:w-1/3 md:w-auto px-5 lg:px-5 xl:px-10 text-primary-lightgray flex-col relative">
                <div className="text-lg font-bold mb-4">Space Guides</div>
                <a href="/resource-center/coworking-space" className="mt-2">
                  About Coworking Spaces
                </a>
                <a href="/resource-center/serviced-office" className="mt-2">
                  About Serviced Offices
                </a>
                <a href="/resource-center/meeting-room" className="mt-2">
                  About Meeting Rooms
                </a>
                <a href="/resource-center/event-space" className="mt-2 mb-10 md:mb-0">
                  About Event Spaces
                </a>
                <a href="/resource-center/traditional-office-space" className="mt-2">
                  About Traditional Offices
                </a>
                <div className="absolute bottom-0 left-0 md:ml-3 lg:ml-5 xl:ml-10 hidden md:block">
                  <div className="flex items-center mb-4">
                    <a href="https://apps.apple.com/us/app/flyspaces/id1620335125" target="blank">
                      <img alt="appstore" width="153" height="55" className="h-10" src={AppStoreBadge}/>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.flyspaces.client&hl=en"
                      target="blank"
                    >
                      <img alt="playstore" width="162" height="55" className="h-10 ml-2" src={PlayStoreBadge}/>
                    </a>
                  </div>
                  <div className="flex items-center">
                    <div className="font-bold mr-2 text-md whitespace-no-wrap">Keep in Touch</div>
                    <a
                      className="ml-3 flex h-4 w-4"
                      href="https://www.facebook.com/flyspaces"
                      target="blank"
                    >
                      <img alt="facebook" width="26" height="26" src={FacebookIcon}/>
                    </a>
                    <a
                      className="ml-3 flex h-4 w-4"
                      href="https://twitter.com/FlySpacesAsia"
                      target="blank"
                    >
                      <img alt="twitter" width="26" height="22" src={TwitterIcon}/>
                    </a>
                    <a
                      className="ml-3 flex h-4 w-4"
                      href="https://www.instagram.com/flyspacesasia/"
                      target="blank"
                    >
                      <img alt="instagram" width="26" height="26" src={InstagramIcon}/>
                    </a>
                    <a
                      className="ml-3 flex h-4 w-4"
                      href="https://www.linkedin.com/company/flyspaces/"
                      target="blank"
                    >
                      <img alt="linkedIn" width="23" height="22" src={LinkedInIcon}/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex w-1/2 sm:w-1/3 md:w-auto px-5 lg:px-5 xl:px-10 text-primary-lightgray flex-col">
                <div className="text-lg font-bold mb-4">Help</div>
                <a href="/about-us" className="mt-2">
                  About Us
                </a>
                <a href="https://explore.flyspaces.com/how-it-works" className="mt-2">
                  How It Works
                </a>
                <a href="/terms-and-conditions" className="mt-2">
                  Terms
                </a>
                <a href="/privacy-policy" className="mt-2">
                  Privacy
                </a>
                <a href="/cancellation-policy" className="mt-2 mb-10">
                  Cancellation
                </a>
              </div>
              <div className="block md:hidden w-1/2 sm:w-1/3 px-5">
                <div className="flex mb-4 flex-col">
                  <a href="https://apps.apple.com/us/app/flyspaces/id1620335125">
                    <img alt="appstore" width="153" height="55" className="w-40 mb-2" src={AppStoreBadge}/>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.flyspaces.client&hl=en">
                    <img alt="playstore" width="162" height="55" className="w-40" src={PlayStoreBadge}/>
                  </a>
                </div>
                <div className="flex flex-col">
                  <div className="font-bold mb-2 text-md whitespace-no-wrap text-primary-lightgray">
                    Keep in Touch
                  </div>
                  <div className="flex">
                    <a
                      className="mr-3 flex h-4 w-4"
                      href="https://www.facebook.com/flyspaces"
                      target="blank"
                    >
                      <img alt="facebook" width="26" height="26" src={FacebookIcon}/>
                    </a>
                    <a
                      className="mr-3 flex h-4 w-4"
                      href="https://twitter.com/FlySpacesAsia"
                      target="blank"
                    >
                      <img alt="twitter" width="26" height="22" src={TwitterIcon}/>
                    </a>
                    <a
                      className="mr-3 flex h-4 w-4"
                      href="https://www.instagram.com/flyspacesasia/"
                      target="blank"
                    >
                      <img alt="instagram" width="26" height="26" src={InstagramIcon}/>
                    </a>
                    <a
                      className="mr-3 flex h-4 w-4"
                      href="https://www.linkedin.com/company/flyspaces/"
                      target="blank"
                    >
                      <img alt="linkedIn" width="23" height="22" src={LinkedInIcon}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SidebarMenuVersion toggle={toggle}/>
        </>
      )}
    </>
  )
}

SidebarMenu.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  dark: PropTypes.bool,
}

SidebarMenu.defaultProps = {
  dark: true,
}

export default Toggler(SidebarMenu)
