export const S3_URL = 'https://media.flyspaces.com'
export const S3_IMAGES_URL = `${S3_URL}/assets/images`
export const S3_ICONS_URL = `${S3_URL}/assets/icons`
export const IMG_LOGO = `${S3_IMAGES_URL}/logo-flyspaces-wh.png?o=true`
export const LogoBlack = `${S3_IMAGES_URL}/logo-flyspaces-bk.png?o=true`
export const graphOneImg = `${S3_IMAGES_URL}/graphic-1.png?o=true`
export const graphTwoImg = `${S3_IMAGES_URL}/graphic-2.png?o=true`
export const graphThreeImg = `${S3_IMAGES_URL}/graphic-3.png?o=true`

export const AppStoreBadge = `${S3_IMAGES_URL}/ios-app-store-badge.png?o=true`
export const PlayStoreBadge = `${S3_IMAGES_URL}/google-play-store-badge.png?o=true`
export const PassportMap = `${S3_IMAGES_URL}/map-resized.png?o=true`

export const IMG_MEDIACORP = `${S3_IMAGES_URL}/mediacorp.png?o=true`
export const IMG_UPWORK = `${S3_IMAGES_URL}/upwork.png?o=true`
export const IMG_GOOGLE = `${S3_IMAGES_URL}/google.png?o=true`
export const IMG_UNILEVER = `${S3_IMAGES_URL}/unilever.png?o=true`
export const IMG_UBER = `${S3_IMAGES_URL}/uber.png?o=true`
export const IMG_ZALORA = `${S3_IMAGES_URL}/zalora.png?o=true`
export const IMG_FOODPANDA = `${S3_IMAGES_URL}/foodpanda.png?o=true`

export const SearchIconWhite = `${S3_ICONS_URL}/menu/search-white.png?o=true`
export const ArrowDownIconWhite = `${S3_ICONS_URL}/menu/arrow-down-white.png?o=true`
export const ArrowUpIcon = `${S3_ICONS_URL}/menu/arrow-up.png?o=true`
export const ArrowUpIconWhite = `${S3_ICONS_URL}/menu/arrow-up-white.png?o=true`
export const ArrowDownIcon = `${S3_ICONS_URL}/menu/arrow-down.png?o=true`
export const SearchIcon = `${S3_ICONS_URL}/menu/search.png?o=true`
export const MenuIcon = `${S3_ICONS_URL}/menu/burger.png?o=true`
export const MenuIconWhite = `${S3_ICONS_URL}/menu/burger-white.png?o=true`
export const CloseIcon = `${S3_ICONS_URL}/menu/close-white.png?o=true`
export const ArrowDownIconBigWhite = `${S3_ICONS_URL}/arrow-down-big-white.png?o=true`

export const FacebookIcon = `${S3_ICONS_URL}/socials/facebook.png?o=true`
export const TwitterIcon = `${S3_ICONS_URL}/socials/twitter.png?o=true`
export const InstagramIcon = `${S3_ICONS_URL}/socials/instagram.png?o=true`
export const LinkedInIcon = `${S3_ICONS_URL}/socials/linkedin.png?o=true`
export const WhatsAppIcon = `${S3_ICONS_URL}/whatsapp.png?o=true`

export const Logo404 = `${S3_ICONS_URL}/404-logo.png?o=true`
