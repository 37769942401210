// /client
export const FETCH_CLIENT_REQUESTS = 'fetchClientRequests'
export const FETCHED_CLIENT_REQUESTS = 'fetchedClientRequests'
export const FETCH_CLIENT_TO_PAY = 'fetchClientToPay'
export const FETCHED_CLIENT_TO_PAY = 'fetchedClientToPay'
export const FETCH_CLIENT_CONFIRMED = 'fetchClientConfirmed'
export const FETCHED_CLIENT_CONFIRMED = 'fetchedClientConfirmed'
export const FETCH_CLIENT_ARCHIVE = 'fetchClientArchive'
export const FETCHED_CLIENT_ARCHIVE = 'fetchedClientArchive'
export const ARCHIVE_CLIENT_REQUEST = 'archiveClientRequest'
export const ARCHIVED_CLIENT_REQUEST = 'archivedClientRequest'
export const CANCEL_CLIENT_REQUEST = 'cancelClientRequest'
export const CANCELLED_CLIENT_REQUEST = 'canceledClientRequest'
export const FETCH_CLIENT_PROPOSAL_PAYMENT_DETAILS = 'fetchClientProposalPaymentDetails'
export const FETCHED_CLIENT_PROPOSAL_PAYMENT_DETAILS = 'fetchedClientProposalPaymentDetails'
export const FETCHED_CLIENT_PROPOSAL_DETAILS = 'fetchedClientProposalDetails'
export const GET_CLIENT_PROPOSAL_DETAILS = 'getClientProposalDetails'
export const PAY_CLIENT_PROPOSAL = 'payClientProposal'
export const PAID_CLIENT_PROPOSAL = 'paidClientProposal'
export const APPLY_CLIENT_PROPOSAL_PROMOCODE = 'applyClientProposalPromoCode'
export const APPLIED_CLIENT_PROPOSAL_PROMOCODE = 'appliedClientProposalPromoCode'

// /merchant
export const FETCH_MERCHANT_LEADS = 'fetchMerchantLeads';
export const FETCHED_MERCHANT_LEADS = 'fetchedMerchantLeads';
export const FETCH_MERCHANT_PROPOSALS = 'fetchMerchantProposals'
export const FETCHED_MERCHANT_PROPOSALS = 'fetchedMerchantProposals'
export const FETCH_MERCHANT_PAID = 'fetchMerchantPaid'
export const FETCHED_MERCHANT_PAID = 'fetchedMerchantPaid'
export const FETCH_MERCHANT_WON_ARCHIVED_LEADS = 'fetchMerchantWonArchivedLeads'
export const FETCHED_MERCHANT_WON_ARCHIVED_LEADS = 'fetchedMerchantWonArchivedLeads'
export const FETCH_MERCHANT_LOST_ARCHIVED_INQUIRIES = 'fetchMerchantLostArchivedInquiries'
export const FETCHED_MERCHANT_LOST_ARCHIVED_INQUIRIES = 'fetchedMerchantLostArchivedInquiries'
export const FETCH_MERCHANT_LOST_ARCHIVED_TRANSACTIONS = 'fetchMerchantLostArchivedTransactions'
export const FETCHED_MERCHANT_LOST_ARCHIVED_TRANSACTIONS = 'fetchdMerchantLostArchivedTransactions'
export const FETCH_MERCHANT_UNRESPONDED_ARCHIVED_LEADS = 'fetchMerchantUnresponedArchivedLeads'
export const FETCHED_MERCHANT_UNRESPONDED_ARCHIVED_LEADS = 'fetchedMerchantUnresponedArchivedLeads'
// export const FETCH_MERCHANT_ARCHIVE = 'fetchMerchantArchive'
// export const FETCHED_MERCHANT_ARCHIVE = 'fetchedMerchantArchive'
export const FETCH_MERCHANT_PASSPORT_BOOKINGS = 'fetchMerchantPassportBookings'
export const FETCHED_MERCHANT_PASSPORT_BOOKINGS = 'fetchedMerchantPassportBookings'
export const ACCEPT_MERCHANT_LEAD = 'acceptMerchantLead'
export const ACCEPTED_MERCHANT_LEAD = 'acceptedMerchantLead'
export const DECLINE_MERCHANT_LEAD = 'declineMerchantLead'
export const DECLINED_MERCHANT_LEAD = 'declinedMerchantLead'
export const MERCHANT_CANCEL_SPACE = 'merchantCancelSpace'
export const MERCHANT_CANCELLED_SPACE = 'merchantCancelledSpace'
export const MERCHANT_SEND_PROPOSAL = 'merchantSendProposal'
export const MERCHANT_SENT_PROPOSAL = 'merchantSentProposal'
export const MERCHANT_EDIT_PROPOSAL = 'merchantEditProposal'
export const MERCHANT_EDITED_PROPOSAL = 'merchantEditedProposal'
export const FETCH_MERCHANT_PROPOSAL_DETAILS = 'fetchMerchantProposalDetails'
export const FETCHED_MERCHANT_PROPOSAL_DETAILS = 'fetchedMerchantProposalDetails'
export const FETCH_MERCHANT_PROPOSAL_OPTIONS = 'fetchMerchantProposalOptions'
export const FETCHED_MERCHANT_PROPOSAL_OPTIONS = 'fetchedMerchantProposalOptions'
export const ARCHIVE_MERCHANT_LEAD = 'archiveMerchantLead'
export const ARCHIVED_MERCHANT_LEAD = 'archivedMerchantLead'
export const MERCHANT_CONFIRM_PASSPORT_BOOKING = 'merchantConfirmPassportBooking'
export const MERCHANT_CONFIRMED_PASSPORT_BOOKING = 'merchantConfirmedPassportBooking'
export const PAGE_MERCHANT_LEADS = 'pageMerchantLeads'
export const PAGE_MERCHANT_PROPOSALS = 'pageMerchantProposals'
export const PAGE_MERCHANT_PAID = 'pageMerchantPaid'
export const PAGE_MERCHANT_ARCHIVE = 'pageMerchantArchive'
export const PAGE_MERCHANT_PASSPORT_BOOKING = 'pageMerchantPassportBooking'
export const SORT_MERCHANT_LEADS = 'sortMerchantLeads'
export const SORT_MERCHANT_PROPOSALS = 'sortMerchantProposals'
export const SORT_MERCHANT_PAID = 'sortMerchantPaid'
export const SORT_MERCHANT_ARCHIVE = 'sortMerchantArchive'
export const SORT_MERCHANT_PASSPORT_BOOKING = 'sortMerchantPassportBooking'

// admin
export const ADMIN_FETCH_BOOKINGS = 'adminFetchBookings'
export const ADMIN_FETCHED_BOOKINGS = 'adminFetchedBookings'
export const ADMIN_FETCH_BOOKING_DETAILS = 'adminFetchBookingDetails'
export const ADMIN_FETCHED_BOOKING_DETAILS = 'adminFetchedBookingDetails'
export const ADMIN_CREATE_BOOKING = 'adminCreateBooking'
export const ADMIN_UPDATE_BOOKING = 'adminUpdateBooking'
export const ADMIN_DELETE_BOOKING = 'adminDeleteBooking'
export const ADMIN_SEND_PROPOSAL_MANUAL = 'adminSendProposalManual'
export const ADMIN_SEND_CONFIRM_PAYMENT = 'adminSendConfirmPayment'

export const EXPORT_BOOKINGS = 'exportBookings'
export const EXPORTED_BOOKINGS = 'exportedBookings'
