import 'scss/client.scss'
import 'core-js'
import 'regenerator-runtime/runtime'
import React from 'react'
import { hydrate, render } from 'react-dom'
import Public from 'providers/public'
import * as serviceWorker from './serviceWorker'

const target = document.getElementById('app')
const renderMethod = module.hot ? render : hydrate
renderMethod(<Public />, target)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
