import map from 'lodash/map'

const requiredFields = {
  name: {
    title: 'Name',
    rule: '',
  },
  email: {
    title: 'Email',
    rule: '',
  },
  city: {
    title: 'City',
    rule: '',
  },
  details: {
    title: 'Details',
    rule: '',
  },
}

const validator = value => {
  const errors = {}

  map(requiredFields, ({ max, min, title }, field) => {
    if (!value[field]) {
      errors[field] = `${title} field is required`
    } else if (value[field].length > max) {
      errors[field] = `Field reached ${max} characters`
    } else if (value[field].length < min) {
      errors[field] = `Field requires a minimum of ${min} characters`
    } else if (
      field === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value[field])
    ) {
      errors[field] = `Invalid email format`
    }
  })

  return errors
}

export default validator
