import { getFeaturedAmenetityQuestions, getOpeningHoursQuestions } from 'utils/questionsHelper';
import { DAYS, SAME_DAYS } from 'constants/date-time';

import { QUESTION_TYPES, GROUP_Q_TYPE } from './question';

export const DISPLAY_PER_PAGE = 10;

export const FORM_SECTIONS = {
  DETAILS: 'details',
  CONTACTS: 'contacts',
  SPACES: 'spaces',
};

const DETAILS_VENUE_DETAILS_QS = [
  [
    {
      id: 'name',
      label: 'Venue Name',
      placeholder: 'Type in venue name',
      questionType: QUESTION_TYPES.INPUT,
    },
    {
      id: 'country_id',
      label: 'Country',
      placeholder: 'Select a country...',
      questionType: QUESTION_TYPES.DROPDOWN,
    },
    {
      id: 'city_id',
      label: 'City',
      placeholder: 'Select a city...',
      questionType: QUESTION_TYPES.DROPDOWN,
    },
    {
      id: 'area_id',
      label: 'Area',
      placeholder: 'Select an area...',
      questionType: QUESTION_TYPES.DROPDOWN,
    },
  ],
  [
    {
      id: 'description',
      label: 'Venue Description',
      placeholder: 'This field will be filled in by the FlySpaces team.',
      questionType: QUESTION_TYPES.TEXT_AREA,
      rows: '4',
      resizableX: true,
      disabled: true,
    },
    {
      id: 'landmarks',
      label: 'Landmarks',
      placeholder: 'Type or Select landmarks...',
      questionType: QUESTION_TYPES.DROPDOWN,
    },
    {
      questionType: GROUP_Q_TYPE.ONE_LINE_GROUP_Q,
      customContainerClass: 'flex mb-4 -mx-2',
      qs: [
        {
          id: 'floorNumber',
          label: 'Flr No.',
          placeholder: 'Flr No.',
          questionType: QUESTION_TYPES.INPUT,
          customClass: 'w-2/4 mx-2',
        },
        {
          id: 'address',
          label: 'Address',
          placeholder: 'Building, Street Name',
          questionType: QUESTION_TYPES.INPUT,
          customClass: 'w-full mx-2',
        },
      ],
    },
  ],
];

const DETAIL_VENUE_MAP_Q = [
  {
    id: 'map',
    label: 'Map',
    questionType: QUESTION_TYPES.MAP,
    isSearchOnMapMove: true,
    lat: 7.1907,
    lng: 125.4553,
    customClass: 'relative h-64 w-full mx-6 mb-10',
    style: {
      borderRadius: '0.25rem',
    },
  },
];

const DETAILS_VENUE_AMENITIES_QS = [1, 2, 3].map(getFeaturedAmenetityQuestions);

const DETAILS_VENUE_OPENING_HOURS_QS = [
  {
    id: `sameAvailabilityMonToFri`,
    label: `Same availability from Monday to Friday`,
    questionType: QUESTION_TYPES.RADIO_BUTTON,
    customClass: 'mx-6',
    checked: true,
    name: 'setSchedule'
  },
  {
    id: `differentSchedule`,
    label: `Different Schedule`,
    questionType: QUESTION_TYPES.RADIO_BUTTON,
    customClass: 'mx-6',
    checked: false,
    name: 'setSchedule'
  },
];

export const allDayOpeningQuestion = DAYS.map(getOpeningHoursQuestions)
export const sameDayOpeningQuestion = SAME_DAYS.map(getOpeningHoursQuestions)

export const DETAILS_QUESTIONS = [
  {
    title: 'Venue Details',
    qSections: [DETAILS_VENUE_DETAILS_QS, DETAIL_VENUE_MAP_Q],
  },
  {
    title: 'Venue Amenities',
    description: 'These 3 Featured Amenities are your opportunity to highlight the unique features of your venue!',
    qSections: [DETAILS_VENUE_AMENITIES_QS],
  },
  {
    title: 'Opening Hours',
    qSections: [DETAILS_VENUE_OPENING_HOURS_QS, sameDayOpeningQuestion],
  },
];
