import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/shared/modal'
import ContactUsForm from 'forms/public/contact-us'

const ContactUs = ({ toggle, title = 'Sit back. Relax. We got this.' }) => (
  <Modal title={title} onClose={toggle} titleClassName="text-base lg:text-xl text-center font-bold">
    <div className="modal-body my-5">
      <ContactUsForm toggle={toggle} />
      <div className="bg-white flex flex-col border-t border-gray-400 pt-3 mt-4">
        <div className="text-center text-xl">In a hurry? Give us a call.</div>
        <div className="flex">
          <div className="flex-1">
            <div className="mt-3 text-center">
              <i className="fa fa-phone" aria-hidden="true" />
              <div className="text-sm font-bold">Singapore</div>
              <div className="text-xs">+65 8779 8960</div>
            </div>
            <div className="mt-3 text-center">
              <i className="fa fa-phone" aria-hidden="true" />
              <div className="text-sm font-bold">Jakarta</div>
              <div className="text-xs">+63 2359 3225</div>
            </div>
            <div className="mt-3 text-center">
              <i className="fa fa-phone" aria-hidden="true" />
              <div className="text-sm font-bold">Malaysia</div>
              <div className="text-xs">+6017 5577369</div>
            </div>
          </div>
          <div className="flex-1">
            <div className="mt-3 text-center">
              <i className="fa fa-phone" aria-hidden="true" />
              <div className="text-sm font-bold">Philippines</div>
              <div className="text-xs">+632 83593225</div>
            </div>
            <div className="mt-3 text-center">
              <i className="fa fa-phone" aria-hidden="true" />
              <div className="text-sm font-bold">Hong Kong</div>
              <div className="text-xs">+852 98255800</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)

ContactUs.propTypes = {
  toggle: PropTypes.func,
  title: PropTypes.string,
}

export default ContactUs
