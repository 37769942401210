export const FETCH_BRANDS = 'fetchBrands'
export const FETCHED_BRANDS = 'fetchedBrands'
export const CREATE_BRAND = 'createBrand'
export const CREATED_BRAND = 'createdBrand'


export const FETCH_BRAND = 'fetchBrand'
export const FETCHED_BRAND = 'fetchedBrand'

export const CLIENT_FETCH_BRAND = 'clientFetchBrand'
export const CLIENT_FETCHED_BRAND = 'clientFetchedBrand'

export const UPDATE_BRAND = 'updateBrand'
export const UPDATED_BRAND = 'updatedBrand'

export const SEARCH_BRAND = 'searchBrand'
