export const FETCH_AREAS = 'fetchAreas'
export const FETCHED_AREAS = 'fetchedAreas'

export const FETCH_AREA = 'fetchArea'
export const FETCHED_AREA = 'fetchedArea'

export const FETCH_AREAS_PER_CITY = 'fetchAreasPerCity'
export const FETCHED_AREAS_PER_CITY = 'fetchedAreaPerCity'
export const RESET_AREAS_PER_CITY = 'resetAreasPerCity'


export const CREATE_AREA = 'createArea'
export const CREATED_AREA = 'createdArea'
export const EDIT_AREA = 'editArea'
export const EDITED_AREA = 'editedArea'
export const DELETE_AREA = 'deleteArea'

export const FETCH_AREA_OPTIONS = 'fetchAreaOptions'
export const FETCHED_AREA_OPTIONS = 'fetchedAreaOptions'

export const QUICK_SEARCH_AREA = 'quickSearchAreas'
export const QUICK_SEARCHED_AREA = 'quickSearchedAreas'
