export const ADMIN_FETCH_LEADS = 'adminFetchLeads';
export const ADMIN_FETCHED_LEADS = 'adminFetchedLeads';

export const FETCH_LEAD_DETAILS = 'fetchLeadDetails'
export const FETCHED_LEAD_DETAILS = 'fetchedLeadDetails'

export const UPDATE_LEAD_DETAILS = 'updateLeadDetails'

export const SCHEDULE_TOUR = 'scheduleTour';
export const SCHEDULED_TOUR = 'scheduledTour';

export const MESSAGE_SPACE = 'messageSpace';
export const MESSAGED_SPACE = 'messagedSpace';

export const CREATE_LEAD = 'createLead'
export const CREATED_LEAD = 'createdLead'

export const DELETE_LEAD = 'deleteLead'
export const DELETED_LEAD = 'deletedLead'

export const BULK_DELETE_LEAD = 'bulkDeleteLead'

export const EXPORT_LEADS = 'exportLeads'
export const EXPORTED_LEADS = 'exportedLeads'

export const REMIND_BOOKING_TO_MERCHANT = 'remindBookingToMerchant'
export const REFRESH_INQUIRY = 'refreshInquiry'

