export const UPLOADING = 'uploading';
export const UPLOADING_IMAGES = 'uploadingImages';
export const UPLOADED_IMAGES = 'uploadedImages';
export const UPLOADING_AVATAR = 'uploadingAvatar';
export const AVATAR_UPLOADED = 'avatarUploaded';
export const UPLOADING_SPACE_IMAGES = 'uploadingSpaceImages'
export const UPLOADED = 'uploaded';

export const UPLOAD_BASIC = 'uploadBasic'
export const UPLOADED_BASIC = 'uploadedBasic'

export const MULTIPLE_UPLOAD = 'multipleUpload'
export const UPLOAD_EDITED_IMAGE = 'uploadEditedImage'
