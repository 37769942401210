export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const SAME_DAYS = ['Monday to Friday', 'Saturday', 'Sunday']

export const HOURS_12_FORMAT = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export const HOURS_24_FORMAT = ['00', ...HOURS_12_FORMAT, '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export const MERIDIEM = ['AM', 'PM'];

const [am, pm] = MERIDIEM;

export const PER_12_HOUR_DROPDOWN = [
  ...HOURS_12_FORMAT.map(h => ({ label: `${h}:00${am}`, value: `${h}:00${am}` })),
  ...HOURS_12_FORMAT.map(h => ({ label: `${h}:00${pm}`, value: `${h}:00${pm}` })),
];

export const PER_24_HOUR_DROPDOWN = [
  ...HOURS_24_FORMAT.map(h => ({ label: `${h}:00:00`, value: `${h}:00:00` })),
];

const compare = (key) => (a, b) => {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
}

export const PER_30_MINUTES_DROPDOWN = [...PER_24_HOUR_DROPDOWN, ...HOURS_24_FORMAT.map(h => ({ label: `${h}:30:00`, value: `${h}:30:00` }))].sort(compare('label'))
