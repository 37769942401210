import React from 'react'
import PropTypes from 'prop-types'
import scrollToId from 'utils/scrollToId'


const ButtonHeader = ({ dark }) => {
  const styleDark = 'text-white bg-yl-primary'
  const styleLight = 'text-primary-black bg-white'
  const textClass = dark ? styleDark : styleLight

  return (
    <li className="relative hidden sm:flex ">
      <div
        onClick={() => scrollToId('contact-us')}
        className={`ml-6 text-base px-6 py-2.5 font-bold rounded-full cursor-pointer ${textClass}`}
      >
        Book A Free Tour Now
      </div>
    </li>
  )
}

ButtonHeader.propTypes = {
  dark: PropTypes.bool,
}

ButtonHeader.defaultProps = {
  dark: true,
}

export default ButtonHeader
