export const FETCH_AMENITIES = 'fetchAmenities'
export const FETCHED_AMENITIES = 'fetchedAmenities'

export const FETCH_AMENITY = 'fetchAmenity'
export const FETCHED_AMENITY = 'fetchedAmenity'

export const FETCH_CATEGORY_AMENITY = 'fetchCategoryAmenity'
export const FETCHED_CATEGORY_AMENITY = 'fetchedCategoryAmenity'

export const FETCH_VENUE_AMENITY = 'fetchVenueAmenity'
export const FETCHED_VENUE_AMENITY = 'fetchedVenueAmenity'

export const CREATE_AMENITY = 'createAmenity'
export const CREATED_AMENITY = 'createdAmenity'
export const EDIT_AMENITY = 'editAmenity'
export const EDITED_AMENITY = 'editedAmenity'
export const DELETE_AMENITY = 'deleteAmenity'
export const DELETED_AMENITY = 'deletedAmenity'

export const FETCH_AMENITY_OPTIONS = 'fetchAmenityOptions'
export const FETCHED_AMENITY_OPTIONS = 'fetchedAmenityOptions'

export const FETCH_BUILDING_AMENITIES = 'fetchBuildingAmenities'
export const FETCHED_BUILDING_AMENITIES = 'fetchedBuildingAmenities'
