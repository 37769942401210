import React from 'react'
import PropTypes from 'prop-types'
import { IMG_LOGO, LogoBlack } from 'constants/imageMapping'

const NavbarLogo = ({ dark }) => {
  // const WhiteLogo = useMemo(() => <img src={IMG_LOGO} alt="Flyspace logo" className="w-32" />, [
  //   IMG_LOGO,
  // ])

  // const BlackLogo = useMemo(() => <img src={LogoBlack} alt="Flyspace logo" className="w-32" />, [
  //   LogoBlack,
  // ])
  return (
    <a href="/" className="py-6">
      <img className={`w-32 ${dark ? 'hidden' : ''}`} src={IMG_LOGO} alt="Flyspace logo" width="128" height="23" />
      <img className={`w-32 ${dark ? '' : 'hidden'}`} src={LogoBlack} alt="Flyspace logo" width="128" height="23" />
    </a>
  )
}

NavbarLogo.propTypes = {
  dark: PropTypes.bool,
}

NavbarLogo.defaultProps = {
  dark: true,
}

export default NavbarLogo
