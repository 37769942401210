export const FETCH_LANDMARKS = 'fetchLandmarks'
export const FETCHED_LANDMARKS = 'fetchedLandmarks'
export const FETCH_LANDMARK = 'fetchLandmark'
export const FETCHED_LANDMARK = 'fetchedLandmark'
export const FETCH_LANDMARK_PER_AREA = 'fetchLandmarkPerArea'
export const FETCHED_LANDMARK_PER_AREA = 'fetchedLandmarkPerArea'
export const RESET_LANDMARK_PER_AREA = 'resetLandmarkPerArea'
export const CREATE_LANDMARK = 'createLandmark'
export const CREATED_LANDMARK = 'createdLandmark'
export const EDIT_LANDMARK = 'editLandmark'
export const EDITED_LANDMARK = 'editedLandmark'
export const DELETE_LANDMARK = 'deleteLandmark'
export const DELETED_LANDMARK = 'deletedLandmark'
