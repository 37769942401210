import React from 'react'
import PropTypes from 'prop-types'

const UserClient = ({ children }) => (
  <a href={'/client/account'} className="flex items-center text-sm">
    {children}
  </a>
)

UserClient.propTypes = {
  children: PropTypes.node,
}

export default UserClient
