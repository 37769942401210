const spaceList = [
  {
    name: 'Philippines',
    url: null,
    children: [
      {
        name: 'Manila',
        url: '/manila/office-space',
      },
      {
        name: 'Davao',
        url: '/davao/office-space',
      },
      {
        name: 'Cebu',
        url: '/cebu/office-space',
      },
    ],
  },
  {
    name: 'Singapore',
    url: '/singapore/office-space',
    children: [],
  },
  {
    name: 'Malaysia',
    url: null,
    children: [
      {
        name: 'Kuala Lumpur',
        url: '/kuala-lumpur/office-space',
      },
      {
        name: 'Penang',
        url: '/penang/office-space',
      },
    ],
  },
  {
    name: 'Indonesia',
    url: null,
    children: [
      {
        name: 'Jakarta',
        url: '/jakarta/office-space',
      },
      {
        name: 'Surabaya',
        url: '/surabaya/office-space',
      },
      {
        name: 'Bali',
        url: '/bali/office-space',
      },
    ],
  },
  {
    name: 'Hong Kong',
    url: '/hong-kong/office-space',
    children: [],
  },
  {
    name: 'Thailand',
    url: '/bangkok/office-space',
    children: [],
  },
];

export default spaceList;
