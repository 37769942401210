import React from 'react'
import PropTypes from 'prop-types'
import ClientMenu from './menu/client'
import MerchantMenu from './menu/merchant'
import AdminMenu from './menu/admin'

const UserMenu = ({ profileRole }) => {
  const switchDropDownMenu = () => {
    switch (profileRole) {
      case 'client':
        return <ClientMenu />
      case 'merchant':
        return <MerchantMenu />
      case 'admin':
        return <AdminMenu />
      default:
        return <div />
    }
  }

  return switchDropDownMenu(profileRole)
}

UserMenu.propTypes = {
  profileRole: PropTypes.string,
}

export default UserMenu
