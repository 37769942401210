import React from 'react'
import LogoutIcon from 'assets/icons/menu/logout.png'
import MessagesIcon from 'assets/icons/menu/messages-icon.png'
import LeadsBookingsIcon from 'assets/icons/menu/leads-bookings.png'
import SpacesIcon from 'assets/icons/menu/manage-spaces.png'


const MerchantMenu = () => (
  <div className="merchant-menu md:absolute md:rounded md:bg-white md:shadow-md md:w-56 md:pt-6 md:pb-4 pl-6 md:mt-10 md:ml-4 text-primary-lightgray text-md py-2">
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/merchant`}>
      <img alt="leads booking" className="w-4 mr-2" src={LeadsBookingsIcon} />
      Leads &amp; Bookings
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/merchant/messages`}>
      <img alt="messages icon" className="w-4 mr-2" src={MessagesIcon} />
      Messages
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/merchant/venues`}>
      <img alt="spaces icon" className="w-4 mr-2" src={SpacesIcon} />
      Manage Spaces
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/merchant/account`}>
      <i className="fa fa-user w-4 mr-2" aria-hidden="true"></i>
      Account
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/logout`}>
      <img alt="logout icon" className="w-4 mr-2" src={LogoutIcon} />
      Log Out
    </a>
  </div>
)

export default MerchantMenu
