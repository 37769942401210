export const FETCH_REVIEWS = 'fetchReviews'
export const FETCHED_REVIEWS = 'fetchedReviews'
export const FETCH_VENUE_REVIEWS = 'fetchVenueReviews'
export const FETCHED_VENUE_REVIEWS = 'fetchedVenueReviews'
export const FETCH_REVIEW = 'fetchReview'
export const FETCHED_REVIEW = 'fetchedReview'
export const SEND_REVIEW = 'sendReview'
export const SENT_REVIEW = 'sentReview'
export const FETCH_REVIEW_OPTIONS = 'fetchReviewOptions'
export const FETCHED_REVIEW_OPTIONS = 'fetchedReviewOptions'

export const SEARCH_REVIEWS = 'searchReviews'
export const DELETE_REVIEW = 'deleteReview'
export const VENUE_DELETE_REVIEW = 'venueDeleteReview'

