import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Fields from './fields'
import validate from './validator'

const ContactForm = ({ placeOptions = [], categoryOptions = [] }) => {
  const categoryOptionsFormatted = categoryOptions.map(category => ({
    label: category.name,
    value: category.slug,
  }))

  return <Fields placeOptions={placeOptions} categoryOptions={categoryOptionsFormatted} />
}
ContactForm.propTypes = {
  placeOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
}

export default reduxForm({ validate })(ContactForm)
