import React from 'react'
import PropTypes from 'prop-types'
import UserClient from './client'
import UserMerchant from './merchant'
import UserAdmin from './admin'

const dataset = [
  { title: 'client', component: UserClient },
  { title: 'merchant', component: UserMerchant },
  { title: 'admin', component: UserAdmin },
]

// NOTE: This exist due to an error with ssr
// SSR seems to not read and rehydrates properly
// so i settled with this static approach.
// feel free to change. [FIXED, too lazy to refactor again]
const UserRedirect = ({ profileRole, children }) => {
  const RenderComponent = dataset.find(datum => datum.title === profileRole).component

  return <RenderComponent>{children}</RenderComponent>
}

UserRedirect.propTypes = {
  profileRole: PropTypes.string,
  children: PropTypes.node,
}

export default UserRedirect
