import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import ListIcon from 'assets/icons/menu/mobile/list.png'
import PassportIcon from 'assets/icons/menu/mobile/passport-mobile.png'
import ResourceIcon from 'assets/icons/menu/mobile/resource.png'
import BlogIcon from 'assets/icons/menu/mobile/blog.png'
import FindIcon from 'assets/icons/menu/mobile/find-space.png'
import ContactUstIcon from 'assets/icons/menu/mobile/contact-us.png'
import CloseIcon from 'assets/icons/menu/close-white.png'
import kebabCase from 'lodash/kebabCase'
import SidebarMenuMobileAuth from './sidebar-menu-mobile-auth'
import Logo from './white-logo'

const SidebarMenuMobile = ({ toggle, city }) => (
  <div className="bg-menu-black fixed top-0 right-0 left-0 p-6 text-secondary-lightgray max-h-screen h-screen overflow-auto flex flex-col md:hidden">
    <img
      className="h-3 fixed top-0 right-0 mt-6 mr-6 cursor-pointer"
      src={CloseIcon}
      onClick={toggle}
    />
    <div className="mb-8">
      <Logo />
    </div>
    <SidebarMenuMobileAuth />
    <div className="mt-4 flex items-center">
      <img alt="list" className="h-4 w-4 mr-2 whitespace-nowrap" src={ListIcon} />
      <a href="https://explore.flyspaces.com/how-it-works/list-your-space">List a Space</a>
    </div>
    <div className="mt-4 flex items-center">
      <img alt="passport" className="h-4 w-4 mr-2 whitespace-nowrap" src={PassportIcon} />
      <a href="/passport">Passport</a>
    </div>
    <div className="mt-4 flex items-center">
      <img alt="find" className="h-4 w-4 mr-2 whitespace-nowrap" src={FindIcon} />
      <a href={`/${kebabCase(city)}/office-space`}>Find a Space</a>
    </div>
    <div className="mt-4 flex items-center">
      <img alt="resource" className="h-4 w-4 mr-2 whitespace-nowrap" src={ResourceIcon} />
      <a href="/resource-center">Resource Center</a>
    </div>
    <div className="mt-4 flex items-center">
      <img alt="blog" className="h-4 w-4 mr-2 whitespace-nowrap" src={BlogIcon} />
      <a href="https://blog.flyspaces.com/">Blog</a>
    </div>
    <div className="mt-4 flex items-center">
      <img alt="contact" className="h-4 w-4 mr-2 whitespace-nowrap" src={ContactUstIcon} />
      <a href="">Contact Us</a>
    </div>
    <div className="py-4 flex items-center justify-center text-primary-lightgray mt-auto">
      <a href="/terms-and-conditions">Terms</a>
      <a className="mx-4" href="/privacy-policy">
        Privacy
      </a>
      <a href="/cancellation-policy">Cancellation</a>
    </div>
  </div>
)

SidebarMenuMobile.propTypes = {
  toggle: PropTypes.func.isRequired,
  city: PropTypes.string
}

const mapStateToProps = ({ search, location, ...state }) => {
  const selector = formValueSelector('home-search')
  // console.log(location)
  return {
    city: selector(state, 'city') || location.city || 'Singapore',
  }
}

export default connect(mapStateToProps)(SidebarMenuMobile)
