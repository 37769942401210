import React from 'react'
import DashboardIcon from 'assets/icons/dashboard.png'
import MessagesIcon from 'assets/icons/messages.png'
import LeadsIcon from 'assets/icons/leads.png'
import UserIcon from 'assets/icons/users.png'
import BookingsIcon from 'assets/icons/bookings.png'
import SpacesIcon from 'assets/icons/spaces.png'
import PassportIcon from 'assets/icons/passport.png'
import SettingsIcon from 'assets/icons/settings.png'
import EMSIcon from 'assets/icons/ems.png'
import LogoutIcon from 'assets/icons/menu/logout.png'

const AdminMenu = () => (
  <div className="admin-menu md:absolute md:rounded md:bg-white md:shadow-md md:w-56 md:pt-6 md:pb-4 pl-6 md:mt-10 md:ml-4 text-primary-lightgray text-md py-2">
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin`}>
      <img alt="dashboard" className="w-4 mr-2 invert-image-color" src={DashboardIcon} />
      Dashboard
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/messages/all`}>
      <img alt="messages" className="w-4 mr-2 invert-image-color" src={MessagesIcon} />
      Messages
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/leads`}>
      <img alt="leads icon" className="w-4 mr-2 invert-image-color" src={LeadsIcon} />
      Leads
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/users`}>
      <img alt="user icon" className="w-4 mr-2 invert-image-color" src={UserIcon} />
      Users
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/bookings`}>
      <img alt="booking" className="w-4 mr-2 invert-image-color" src={BookingsIcon} />
      Bookings
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/spaces`}>
      <img alt="spaces" className="w-4 mr-2 invert-image-color" src={SpacesIcon} />
      Spaces
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/passport`}>
      <img alt="passport" className="w-4 mr-2 invert-image-color" src={PassportIcon} />
      Passport
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/settings`}>
      <img alt="setting" className="w-4 mr-2 invert-image-color" src={SettingsIcon} />
      Settings
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/admin/ems`}>
      <img alt="ems" className="w-4 mr-2 invert-image-color" src={EMSIcon} />
      EMS
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/logout`}>
      <img alt="logout" className="w-4 mr-2" src={LogoutIcon} />
      Log Out
    </a>
  </div>
)

export default AdminMenu
