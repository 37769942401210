import React from 'react'
import { Provider } from 'react-redux'
import { compose } from 'redux'
import { hot } from 'react-hot-loader/root'
import useStore from 'store'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes/public'
import isDevelop from 'utils/isDevelop'
import chromeExtComposer from 'utils/chromeExtComposer'
import Navbar from 'components/shared/navbar'
import Footer from 'components/shared/footer'

const composer = isDevelop ? chromeExtComposer : compose

const { preloadedState } = window

const store = useStore(composer, preloadedState)

const Client = () => (
  <Provider store={store}>
    <Router>
      <Navbar />
      <Routes />
      <Footer />
    </Router>
  </Provider>
)

export default hot(Client)
