import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Toggler from 'hoc/toggler'
import isEmpty from 'lodash/isEmpty'
import AccountIcon from 'assets/icons/menu/mobile/account.png'
import UserMenu from './user-menu'

const SidebarMenuMobileAuth = ({ user: { name }, isOpen, toggle, profileRole }) => {
  return name ? (
    <div>
      <div className="mt-4 flex items-center">
        <img alt="account" className="h-4 w-4 mr-2 whitespace-nowrap" src={AccountIcon} />
        <div className="cursor-pointer" onClick={toggle}>
          {name}
        </div>
      </div>
      {isOpen && <UserMenu profileRole={profileRole} />}
    </div>
  ) : (
    <div className="mt-4 flex items-center">
      <img alt="account" className="h-4 w-4 mr-2 whitespace-nowrap" src={AccountIcon} />
      <a href="/login">Log In / Sign Up</a>
    </div>
  )
}

SidebarMenuMobileAuth.propTypes = {
  user: PropTypes.object,
  profileRole: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user || {},
  profileRole:
    auth.user && !isEmpty(auth.user.roles) ? auth.user.roles[0].name || auth.user.roles[0] : '', // since public route utilizes array object and auth/admin side uses plain array
})

export default connect(mapStateToProps)(Toggler(SidebarMenuMobileAuth))
