// admin
export const ADMIN_FETCH_BUILDINGS = 'adminFetchBuildings'

export const ADMIN_FETCHED_BUILDINGS = 'adminFetchedBuildings'
export const ADMIN_FETCH_BUILDING_DETAILS = 'adminFetchBuildingDetails'
export const ADMIN_FETCHED_BUILDING_DETAILS = 'adminFetchedBuildingDetails'

export const ADMIN_CREATE_BUILDING = 'adminCreateBuilding'
export const ADMIN_UPDATE_BUILDING = 'adminUpdateBuilding'

export const ADMIN_CHANGE_PUBLISH = 'adminChangePublish'

export const ADMIN_DELETE_BUILDING = 'adminDeleteBuilding'

export const SELECT_BUILDING = 'selectBuilding'

export const ADMIN_FETCH_BUILDING_USE = 'adminFetchBuildingUse'
export const ADMIN_FETCHED_BUILDING_USE = 'adminFetchedBuildingUse'

export const ADMIN_FETCH_BUILDING_GM_IMGS = 'adminFetchBuildingGoogleMapImages'
export const ADMIN_FETCHED_BUILDING_GM_IMGS = 'adminFetchedBuildingGoogleMapImages'
export const SET_FETCH = 'setFetching'

export const EXPORT_BUILDINGS = 'adminExportBuildings'

export const FETCH_BUILDING_DETAILS_REQUEST = 'fetchBuildingDetailsRequest'
export const FETCH_BUILDING_DETAILS_SUCCESS = 'fetchBuildingDetailsSuccess'

export const FETCH_SUGGESTION_BUILDING_REQUEST = 'fetchSuggestionBuildingsRequest'
export const FETCH_SUGGESTION_BUILDING_SUCCESS = 'fetchSuggestionBuildingsSuccess'

export const FETCH_BUILDING_LISTING_VENUE_REQUEST = 'fetchBuildingListingVenueRequest'
export const FETCH_BUILDING_LISTING_VENUE_SUCCESS = 'fetchBuildingListingVenueSuccess'

export const FETCH_LAND_LORD = 'fetchLandLord'
export const FETCHED_LAND_LORD = 'fetchedLandLord'

export const FETCH_GMAP = 'fetchGmap'
export const FETCHED_GMAP = 'fetchedGmap'
export const FETCH_PUBLIC_TRANSPORTS = 'fetchPublicTransports'
export const FETCHED_PUBLIC_TRANSPORTS = 'fetchedPublicTransports'

export const FETCH_COMMERCIAL_BUILDING_AREAS = 'fetchCommercialBuildingAreas'
export const FETCHED_COMMERCIAL_BUILDING_AREAS = 'fetchedCommercialBuildingAreas'

export const FETCH_COMMERCIAL_BUILDINGS = 'fetchCommercialBuildings'
export const FETCHED_COMMERCIAL_BUILDINGS = 'fetchedCommercialBuildings'

export const FETCH_BUILDING_OFFICE_TABLE = 'fetchBuildingOfficeTable'
export const FETCHED_BUILDING_OFFICE_TABLE = 'fetchedBuildingOfficeTable'
export const FETCH_BUILDING_OFFICE_VENUE_CARD = 'fetchBuildingOfficeVenueCard'
export const FETCHED_BUILDING_OFFICE_VENUE_CARD = 'fetchedBuildingOfficeVenueCard'

export const ADMIN_FETCH_BUILDING_AREA = 'adminFetchBuildingArea'
export const ADMIN_FETCHED_BUILDING_AREA = 'adminFetchedBuildingArea'
