import loadable from '@loadable/component'
import Notification from /* webpackChunkName: "login" */'components/shared/notification'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import NotFound from /* webpackChunkName: "login" */'routes/404'

const About = loadable(() => import('components/public/about-us'))
const Cancelation = loadable(() =>
  import('components/public/cancellation-policy/cancellation-policy')
)
const CoworkingSpace = loadable(() => import('components/public/category-pages/coworking-space'))
const EventSpace = loadable(() => import('components/public/category-pages/event-space'))
const MeetingRoom = loadable(() => import('components/public/category-pages/meeting-room'))
const ServicedOffice = loadable(() => import('components/public/category-pages/serviced-office'))
const TraditionalOffice = loadable(() =>
  import('components/public/category-pages/traditional-office')
)
const ContactUs = loadable(() => import('components/public/contact-us'))
const Home = loadable(() => import(/* webpackPreload: true */'components/public/home'))
const LandingPage = loadable(() => import('components/public/landing-page'))
const OfficeBuildingArea = loadable(() =>
  import('components/public/office-building-list/office-building-area')
)
const OfficeBuildings = loadable(() => import('components/public/office-building-list'))
const Passport = loadable(() => import('components/public/passport'))
const PassportCheckIn = loadable(() => import('components/public/passport/passport-check-in'))
const PassportCity = loadable(() => import('components/public/passport/passport-cities'))
const PassportRegional = loadable(() =>
  import('components/public/passport/passport-cities/passport-regional')
)
const PassportRegionalPayment = loadable(() =>
  import('components/public/passport/passport-cities/passport-regional/passport-payment')
)
const PassportTrialRegional = loadable(() =>
  import('components/public/passport/passport-cities/passport-regional/passport-trial')
)
const PassportTrial = loadable(() =>
  import('components/public/passport/passport-cities/passport-trial')
)
const PassportSuccess = loadable(() => import('components/public/passport/passport-success'))
const PassportListing = loadable(() => import('components/public/passport/search'))
const Privacy = loadable(() => import('components/public/privacy-policy/privacy-policy'))
const ResourceCenter = loadable(() => import('components/public/resource-center'))
const BrandPages = loadable(() => import('components/public/space/brand-pages'))
const SampleSpaceWithVideo = loadable(() => import('components/public/space/sample-space'))
const Terms = loadable(() => import('components/public/terms-and-condition/terms-and-condition'))
const CitySearchSwitcher = loadable(() => import('containers/public/city-search-switcher'))
const PassportSpaceSwitcher = loadable(() => import('containers/public/passport-space-switcher'))
const VenueBrandSwitcher = loadable(() => import('containers/public/venue-brand-switcher'))
const ChangePassword = loadable(() => import(/* webpackChunkName: "login" */'forms/auth/change-password'))
const Login = loadable(() => import(/* webpackChunkName: "login" */'forms/auth/login'))
const Register = loadable(() => import(/* webpackChunkName: "login" */'forms/auth/register'))
const ResetPassword = loadable(() => import(/* webpackChunkName: "login" */'forms/auth/reset-password'))
const BuildingPage = loadable(() => import('components/public/building-pages'))

const Public = ({ match, notification: { type, message } }) => {
  const items = [
    {
      path: `${match.path}:city/premium-merchant/:brandName/:brandId`,
      component: BrandPages,
      exact: true,
    },
    {
      path: `${match.path}:city/building/:buildingName/:buildingId`,
      component: BuildingPage,
      exact: true,
    },
    {
      path: '/:city/:venue([a-z0-9-]+)/:id(\\d+)',
      component: VenueBrandSwitcher,
      exact: true,
    },
    {
      path: '/:city/:venue([a-z0-9-]+)/:category([a-z0-9-]+)/:id(\\d+)',
      component: PassportSpaceSwitcher,
      exact: true,
    },
    {
      path: '/:city/:venue([a-z0-9-]+)/:category([a-z0-9-]+)/:id(\\d+)/check-in',
      component: PassportCheckIn,
      exact: true,
    },
    {
      path: '/:city/:venue([a-z0-9-]+)/:category([a-z0-9-]+)/:id(\\d+)/confirmation',
      component: PassportSuccess,
      exact: true,
    },
    {
      path: `${match.path}sample-space`,
      component: SampleSpaceWithVideo,
      exact: true,
    },
    {
      path: `${match.path}login`,
      component: Login,
      exact: true,
    },
    {
      path: `${match.path}recovery`,
      component: ResetPassword,
      exact: false,
    },
    {
      path: `${match.path}password-change/:token`,
      component: ChangePassword,
      exact: false,
    },
    {
      path: `${match.path}register`,
      component: Register,
      exact: false,
    },
    {
      path: `${match.path}about-us`,
      component: About,
      exact: false,
    },
    {
      path: `${match.path}contact-us`,
      component: ContactUs,
      exact: false,
    },
    {
      path: `${match.path}building-page`,
      component: BuildingPage,
      exact: false,
    },
    {
      path: `${match.path}resource-center/coworking-space`,
      component: CoworkingSpace,
      exact: false,
    },
    {
      path: `${match.path}resource-center/serviced-office`,
      component: ServicedOffice,
      exact: false,
    },
    {
      path: `${match.path}resource-center/meeting-room`,
      component: MeetingRoom,
      exact: false,
    },
    {
      path: `${match.path}resource-center/event-space`,
      component: EventSpace,
      exact: false,
    },
    {
      path: `${match.path}resource-center/traditional-office-space`,
      component: TraditionalOffice,
      exact: false,
    },
    {
      path: `${match.path}resource-center`,
      component: ResourceCenter,
      exact: false,
    },
    {
      path: `${match.path}terms-and-conditions`,
      component: Terms,
      exact: false,
    },
    {
      path: `${match.path}cancellation-policy`,
      component: Cancelation,
      exact: false,
    },
    {
      path: `${match.path}privacy-policy`,
      component: Privacy,
      exact: false,
    },
    {
      path: `${match.path}passport-listing/:passportCity`,
      component: PassportListing,
      exact: false,
    },
    {
      path: `${match.path}passport/trial/:city`,
      component: PassportTrial,
      exact: false,
    },
    {
      path: `${match.path}passport/trial/regional`,
      component: PassportTrialRegional,
      exact: false,
    },
    {
      path: `${match.path}passport/pay/starter/regional`,
      component: PassportRegionalPayment,
      exact: false,
    },
    {
      path: `${match.path}passport/regional`,
      component: PassportRegional,
      exact: false,
    },
    {
      path: `${match.path}passport/:city`,
      component: PassportCity,
      exact: false,
    },
    {
      path: `${match.path}passport`,
      component: Passport,
      exact: false,
    },
    {
      path: `${match.path}office-buildings/:city/:area`,
      component: OfficeBuildingArea,
      exact: true,
    },
    {
      path: `${match.path}office-buildings`,
      component: OfficeBuildings,
      exact: true,
    },
    {
      path: `${match.path}404`,
      component: NotFound,
      exact: false,
    },
    {
      path: `${match.path}:primaryParam/:secondaryParam`,
      component: CitySearchSwitcher,
      exact: true,
    },
    {
      path: `${match.path}`,
      component: Home,
      exact: true,
    },
    {
      path: `${match.path}:city/office-for-rent/inquire`,
      component: LandingPage,
      exact: true,
    },
    {
      component: NotFound,
    },
  ]
  return (
    <>
      {type && <Notification type={type} message={message} />}
      <div className="min-h-screen">
        <Switch>
          {items.map(({ component, path, exact }, key) =>
            !path ? (
              <Route key={key} component={component} />
            ) : (
              <Route key={key} exact={exact} path={path} component={component} />
            )
          )}
        </Switch>
      </div>
    </>
  )
}
Public.propTypes = {
  match: PropTypes.object.isRequired,
  notification: PropTypes.object,
}

const mapStateToProps = ({ notification }) => ({
  notification,
})

export default withRouter(connect(mapStateToProps)(Public))
