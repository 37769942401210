import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SubmitButton from 'forms/shared/buttons/submit'
import placesActions from 'actions/places/actions'
import searchActions from 'actions/search/actions'
import generalActions from 'actions/general/actions'
import isEmpty from 'lodash/isEmpty'
import ContactUsForm from './form'

const ContactUs = ({
  places,
  categories,
  toggle,
  sendContact,
  fetchPlaceOptions,
  fetchSearchOptions,
  notification: { type },
}) => {
  useEffect(() => {
    isEmpty(places) && fetchPlaceOptions({ published: true })
    isEmpty(categories) && fetchSearchOptions()
  }, [])

  const formName = 'public-contact-us-form'

  return (
    <div className="bg-white relative flex flex-col">
      {type === 'success' && toggle()}
      <ContactUsForm
        form={formName}
        onSubmit={values => sendContact(values)}
        placeOptions={places}
        categoryOptions={categories}
      />
      <SubmitButton
        className="px-4 py-2 mt-3 m-auto rounded-lg bg-orange-400 hover:bg-orange-300 text-white"
        formName={formName}
      >
        SEND MESSAGE
      </SubmitButton>
    </div>
  )
}

ContactUs.propTypes = {
  sendContact: PropTypes.func.isRequired,
  fetchPlaceOptions: PropTypes.func.isRequired,
  fetchSearchOptions: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  places: PropTypes.array,
  categories: PropTypes.array,
  notification: PropTypes.object.isRequired,
}

const mapStateToProps = ({ places, search, notification }) => ({
  places: places.options,
  categories: search.options.categories,
  notification,
})

const mapDispatchToProps = {
  sendContact: generalActions.sendContact,
  fetchPlaceOptions: placesActions.fetchPlaceOptions,
  fetchSearchOptions: searchActions.fetchSearchOptions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactUs)
