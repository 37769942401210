export const FETCH_COUNTRIES = 'fetchCountries';
export const FETCHED_COUNTRIES = 'fetchedCountries';

export const FETCH_COUNTRY_CITIES = 'fetchCountryCities'
export const FETCHED_COUNTRY_CITIES = 'fetchedCountryCities'
export const RESET_COUNTRY_CITIES = 'resetCountryCities'

export const FETCH_COUNTRIES_WITH_CITIES = 'fetchCountriesWithCities';
export const FETCHED_COUNTRIES_WITH_CITIES = 'fetchedCountriesWithCities';

export const FETCH_COUNTRY_OPTIONS = 'fetchCountryOptions';
export const FETCHED_COUNTRY_OPTIONS = 'fetchedContryOptions';
