import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import * as userTypes from 'actions/users/types'
import SpaceMenu from './space'
import AuthMenu from './auth'
import SidebarMenu from './sidebar-menu'
import ButtonHeader from './button-header'

const NavRight = ({ dark, adminLoginAsInfo: { email, code }, switchToAdmin, auth }) => {
  const location = useLocation()
  const handleSwitchToAdmin = async () => {
    await switchToAdmin({ email, code })
    window.location.assign('/admin')
  }
  const darkTextClass = 'text-primary-black'

  const whiteTextClass = 'text-white'
  const isShowSwitchAdmin = email && email !== auth?.user?.email

  const textClass = dark ? darkTextClass : whiteTextClass
  return (
    <ul className={`flex items-center ${textClass}`}>
      <SpaceMenu dark={dark} />
      {location.pathname.split('/').slice(-2).join('/') === 'office-for-rent/inquire' ? (
        <ButtonHeader dark={dark} />
      ) : (
        <AuthMenu dark={dark} />
      )}
      <SidebarMenu dark={dark} />
      {isShowSwitchAdmin && (
        <li
          onClick={handleSwitchToAdmin}
          className={`ml-10 font-semibold cursor-pointer ${
            dark ? 'text-primary-black' : 'text-white'
          }`}
        >
          Switch to Admin
        </li>
      )}
    </ul>
  )
}

NavRight.propTypes = {
  dark: PropTypes.bool,
  adminLoginAsInfo: PropTypes.object,
  switchToAdmin: PropTypes.func,
  auth: PropTypes.object,
}

NavRight.defaultProps = {
  dark: true,
  adminLoginAsInfo: Cookies.getJSON('adminLoginAsInfo') || {},
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const mapDispatchToProps = (dispatch) => ({
  switchToAdmin: (payload) =>
    new Promise((resolve, reject) => {
      dispatch({ type: userTypes.SWITCH_TO_ADMIN, payload, resolve, reject })
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavRight)
