import React from 'react';
import { Link } from 'react-router-dom';
import { IMG_LOGO } from 'constants/imageMapping';

const NavbarLogo = () => (
  <Link to="/" className="py-6">
    <img alt="logo" src={IMG_LOGO} className="w-32" width="128" height="23" />
  </Link>
);

export default NavbarLogo;
