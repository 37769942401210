import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Public from 'containers/public'
import NotFound from './404'
import ErrorBoundary from './error-boundary'

const SSRRoutes = () => (
  <ErrorBoundary>
    <Switch>
      <Route path="/" component={Public} />
      <Route component={NotFound} />
    </Switch>
  </ErrorBoundary>
)

export default SSRRoutes
