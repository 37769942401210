import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserDropdown from './user-dropdown'

const AuthMenu = ({ user: { name, profile, roles }, dark }) => {
  const handleAuthenticated = () => {
    const role = roles[0].name ? roles[0].name : roles[0]

    return <UserDropdown dark={dark} username={name} avatar={profile.avatar} profileRole={role} />
  }

  const darkTextClass = 'text-primary-black'

  const whiteTextClass = 'text-white'

  const textClass = dark ? darkTextClass : whiteTextClass

  return name ? (
    handleAuthenticated()
  ) : (
    <li className="relative hidden sm:flex">
      <a href="/login" className={`ml-4 text-base ${textClass}`}>
        Log In
      </a>
    </li>
  )
}

AuthMenu.propTypes = {
  user: PropTypes.object,
  dark: PropTypes.bool,
}

AuthMenu.defaultProps = {
  dark: true,
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user || {},
})

export default connect(mapStateToProps)(AuthMenu)
