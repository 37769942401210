import React from 'react';
import PropTypes from 'prop-types';
import SpaceDropdown from './space-dropdown';

const SpaceMenu = ({ dark }) => (
  <SpaceDropdown dark={dark} />
);

SpaceMenu.propTypes = {
  user: PropTypes.object,
  dark: PropTypes.bool,
};

SpaceMenu.defaultProps = {
  dark: true,
}

export default SpaceMenu;
