import React from 'react'
import LogoutIcon from 'assets/icons/menu/logout.png'
import MessagesIcon from 'assets/icons/menu/messages-icon.png'
import LeadsBookingsIcon from 'assets/icons/menu/leads-bookings.png'

const ClientMenu = () => (
  <div className="user-menu md:absolute md:rounded md:bg-white md:shadow-md md:w-56 md:pt-6 md:pb-4 pl-6 md:mt-10 md:ml-4 text-primary-lightgray text-md py-2">
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/client`}>
      <img alt="leads booking" className="w-4 mr-2" src={LeadsBookingsIcon} />
      Bookings
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/client/messages`}>
      <img alt="messages icon" className="w-4 mr-2" src={MessagesIcon} />
      Messages
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/client/passport`}>
      <i className="fa fa-map-o w-4 mr-2" aria-hidden="true"></i>
      Passport
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/client/account`}>
      <i className="fa fa-user w-4 mr-2" aria-hidden="true"></i>
      Account
    </a>
    <a className="flex items-center mb-2 hover:text-primary-darkgray" href={`/logout`}>
      <img alt="logout icon" className="w-4 mr-2" src={LogoutIcon} />
      Log Out
    </a>
  </div>
)

export default ClientMenu
